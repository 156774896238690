<template>
  <transition name="slide-top">
    <div
      class="info"
      v-if="show && this.message"
      :style="{ backgroundColor: background }"
    >
      <p>{{ message }}</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        @click="close"
      >
        <path
          id="close-circle"
          d="M12,2A10,10,0,1,1,2,12,9.991,9.991,0,0,1,12,2m3.59,5L12,10.59,8.41,7,7,8.41,10.59,12,7,15.59,8.41,17,12,13.41,15.59,17,17,15.59,13.41,12,17,8.41Z"
          transform="translate(-2 -2)"
          fill="#fff"
        />
      </svg>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      background: "#a50707",
      message: this.$t("info"),
    };
  },
  methods: {
    close() {
      this.show = false;
      localStorage.setItem("confirmedMessage", this.message);
    },
  },
  mounted() {
    setTimeout(() => {
      const cm = localStorage.getItem("confirmedMessage");

      if (this.message !== cm) {
        this.show = true;
      }
    }, 10);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/transition.scss";
@import "@/scss/mixins.scss";
.info {
  align-items: center;
  background: #632218;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 2px;
  position: fixed;
  width: 100%;
  z-index: 100000000000000;
  p {
    font-weight: 300;
    padding: 0 10px;
    text-align: center;
    width: 100%;
  }
  svg {
    width: 20px;
    @include hover();
  }
}

@media screen and (max-width: $s) {
  .info {
    p {
      text-align: left;
      padding-left: 0;
    }
  }
}
</style>