<template>
  <div class="home">
    <ParallaxCarousel :slides="slides" />
    <About />
    <div class="preview-wrapper">
      <Preview :img="'assets/img/restaurant/bg.jpg'" :svg="`assets/img/restaurant/svg_${$i18n.locale}.svg`"
        :alt="this.$t('home-pr-gourmet-alt')" :p="$t('home-pr-gourmet-p')" :linkTo="'/restaurant'"
        :linkTexts="$t('home-pr-gourmet-link')" />
      <Preview :invert="true" :bg="'linear-gradient(rgba(0,0,0,1) 0%, rgba(54,54,54,1) 100%)'"
        :img="'assets/img/loyal/bg.jpg'" :svg="`assets/img/loyal/svg_${$i18n.locale}.svg`"
        :alt="this.$t('home-pr-loyal-alt')" :p="$t('home-pr-loyal-p')" :linkTo="'/loyalty'"
        :linkTexts="$t('home-pr-loyal-link')" />
      <PreviewCarousel :previews="previews" />
    </div>
  </div>
</template>

<script>
import ParallaxCarousel from "@/components/ParallaxCarousel";
import About from "@/views/home/About";
import Preview from "@/components/Preview";
import PreviewCarousel from "@/components/PreviewCarousel";
export default {
  name: "Home",
  components: {
    ParallaxCarousel,
    About,
    Preview,
    PreviewCarousel,
  },
  data() {
    return {
      slides: [
        // НОВОГОДИШНА ОФЕРТА
        // {
        //   bg: "assets/img/nye/home-b.mp4",
        //   video: true,
        //   wait: true,
        //   poster: "assets/img/nye/img1.jpg",
        //   svg: `assets/img/nye/nye_${this.$i18n.locale}.svg`,
        //   // alt: this.$t("parallax-alt-vipski"),
        //   to: "/offers/nye",
        // },
        // //Банери за нова година-Босфор - NewYearB.vue
        // {
        //   bg: "assets/img/nye/home-b.mp4",
        //   video: true,
        //   wait: true,
        //   poster: "assets/img/nye/img1.jpg",
        //   svg: `assets/img/nye/bosfor_${this.$i18n.locale}.svg`,
        //   // alt: this.$t("parallax-alt-vipski"),
        //   to: "/offers/nye-bosphorus",
        // },
        // //Банери за нова година-Ресторант - NewYearR.vue
        // {
        //   bg: "assets/img/nye/home-r.mp4",
        //   video: true,
        //   wait: true,
        //   poster: "assets/img/nye/img.jpg",
        //   svg: `assets/img/nye/restaurant_${this.$i18n.locale}.svg`,
        //   // alt: this.$t("parallax-alt-vipski"),
        //   to: "/offers/nye-restaurant",
        // },
        // Декемврийско бягство
        // {
        //   bg: "assets/img/offers/december-escape/bg.jpg",
        //   svg: `assets/img/offers/december-escape/svg_${this.$i18n.locale}.svg`,
        //   alt: this.$t("parallax-alt-december-escape"),
        //   gradient: "#0470B1",
        //   to: "/offers/december-escape",
        // },
        // СКИ ОФЕРТИ ЗАКУСКА + ВЕЧЕРЯ
        // {
        //   bg: "assets/img/offers/ski-packages-breakfast&dinner-included/bg.jpg",
        //   svg: `assets/img/offers/ski-packages-breakfast&dinner-included/svg_${this.$i18n.locale}.svg`,
        //   alt: this.$t("parallax-alt-ski-packages-breakfast&dinner-included"),
        //   gradient: "#0470B1",
        //   to: "/offers/ski-packages-breakfast&dinner-included",
        // },
        // СКИ ОФЕРТИ ЗАКУСКА
        // {
        //   bg: "assets/img/offers/ski-packages-breakfast-included/bg.jpg",
        //   svg: `assets/img/offers/ski-packages-breakfast-included/svg_${this.$i18n.locale}.svg`,
        //   alt: this.$t("parallax-alt-ski-packages-breakfast-included"),
        //   gradient: "#0470B1",
        //   to: "/offers/ski-packages-breakfast-included",
        // },
        // ДЕЛНИЧНО БЯГСТВО ПРЕЗ МАРТ
        // {
        //   bg: "assets/img/offers/march-escape-2024/bg.jpg",
        //   svg: `assets/img/offers/march-escape-2024/svg_${this.$i18n.locale}.svg`,
        //   alt: this.$t("parallax-alt-march-escape-2024"),
        //   gradient: "#0470B1",
        //   to: "/offers/march-escape-2024",
        // },
        // ПЛАНИНСКИ РЕЛАКС 2024
        {
          bg: "assets/img/offers/mountain-relax-2024/bg.jpg",
          svg: `assets/img/offers/mountain-relax-2024/svg_${this.$i18n.locale}.svg`,
          alt: this.$t("parallax-alt-mountain-relax-2024"),
          gradient: "#100901",
          to: "/offers/mountain-relax-2024",
        },
        // Зимно бягство
        {
          bg: "assets/img/offers/winter-escape-2025/bg.jpg",
          svg: `assets/img/offers/winter-escape-2025/svg_${this.$i18n.locale}.svg`,
          alt: this.$t("winter-escape-2025"),
          gradient: "#38280F",
          to: "/offers/winter-escape-2025",
        },

        // ПЛАНИНСКИ РЕЛАКС 2025
        {
          bg: "assets/img/offers/mountain-relax-2025/bg.jpg",
          svg: `assets/img/offers/mountain-relax-2025/svg_${this.$i18n.locale}.svg`,
          alt: this.$t("parallax-alt-mountain-relax-2025"),
          gradient: "#100901",
          to: "/offers/mountain-relax-2025",
        },

        // ВЕЛИКДЕН
        {
          bg: "assets/img/offers/easter-2025/bg.jpg",
          svg: `assets/img/offers/easter-2025/svg_${this.$i18n.locale}.svg`,
          alt: this.$t("parallax-alt-EASTER2025"),
          gradient: "#100901",
          to: "/offers/easter-2025",
        },

        // ПЛАНИНСКО ЛЯТО & АКВАПАРК 2025
        {
          bg: "assets/img/offers/mountain-summer-aquapark-2025/bg.jpg",
          svg: `assets/img/offers/mountain-summer-aquapark-2025/svg_${this.$i18n.locale}.svg`,
          alt: this.$t("mountain-summer-aquapark-2025"),
          gradient: "#100901",
          to: "/offers/mountain-summer-aquapark-2025",
        },

        // Баня
        {
          bg: "assets/img/home/parallax/banya_bg.jpg",
          svg: `assets/img/home/parallax/b-${this.$i18n.locale}.svg`,
          alt: this.$t("parallax-alt-banya"),
          to: "/banya",
        },
        // SPA
        {
          bg: "assets/img/home/parallax/home3_bg.jpg",
          svg: `assets/img/home/parallax/spa-${this.$i18n.locale}.svg`,
          alt: this.$t("parallax-alt-spa"),
          gradient: "orange",
          to: "/spa",
        },

        // {
        //   bg: "assets/img/offers/202202/bg.jpg",
        //   svg: `assets/img/offers/202202/svg_${this.$i18n.locale}.svg`,
        //   alt: this.$t("parallax-alt-202202"),
        //   gradient: "#003F52",
        //   to: "/offers/winter-family-vacation",
        // },

        // {
        //   bg: "assets/img/offers/vipski/vipski.mp4",
        //   video: true,
        //   wait: true,
        //   poster: "assets/img/offers/vipski/vipski.jpg",
        //   svg: `assets/img/offers/vipski/svg_${this.$i18n.locale}.svg`,
        //   alt: this.$t("parallax-alt-vipski"),
        //   to: "/offers/vip-ski-pass",
        // },

        //{
        //   bg: "assets/img/offers/mountain-spa-relax/bg.jpg",
        //   svg: `assets/img/offers/mountain-spa-relax/svg_${this.$i18n.locale}.svg`,
        //   alt: this.$t("mountain-spa-relax"),
        //   gradient: "#0470B1",
        //   to: "/offers/mountain-spa-relax",
        // },
      ],
      previews: [
        {
          bg: "#003A82",
          img: "assets/img/banya/bg.jpg",
          svg: `assets/img/banya/svg_${this.$i18n.locale}.svg`,
          alt: this.$t("home-pr-banya-alt"),
          p: this.$t("home-pr-banya-p"),
          linkTo: "/banya",
          linkTexts: this.$t("home-pr-banya-link"),
        },
        {
          bg: "#003A82",
          img: "assets/img/aquapark/bg.jpg",
          svg: `assets/img/aquapark/svg_${this.$i18n.locale}.svg`,
          alt: this.$t("home-pr-aquapark-alt"),
          p: this.$t("home-pr-aquapark-p"),
          linkTo: "/aquapark",
          linkTexts: this.$t("home-pr-aquapark-link"),
        },
        {
          bg: "#003A82",
          img: "assets/img/spa/bg.jpg",
          svg: `assets/img/spa/svg_${this.$i18n.locale}.svg`,
          alt: this.$t("home-pr-spa-alt"),
          p: this.$t("home-pr-spa-p"),
          linkTo: "/spa",
          linkTexts: this.$t("home-pr-spa-link"),
        },
        {
          bg: "#003A82",
          img: "assets/img/kids/bg.jpg",
          svg: `assets/img/kids/svg_${this.$i18n.locale}.svg`,
          alt: this.$t("home-pr-kids-alt"),
          p: this.$t("home-pr-kids-p"),
          linkTo: "/kids",
          linkTexts: this.$t("home-pr-kids-link"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.preview-wrapper {
  background: linear-gradient(#fff 0%, #f2f2f2 32%, #d4d4d4 100%);
  display: flex;
  flex-direction: column;

  .preview {
    &:first-child {
      margin-top: -1.5rem;
    }
  }
}
</style>
